// NOTE: Include all helper mixins and styles

@import 'helpers/functions';
@import 'helpers/variables';
@import 'helpers/mixins';

@import 'helpers/display';
@import 'helpers/position';
@import 'helpers/size';
@import 'helpers/spacing';
@import 'helpers/border';
@import 'helpers/radius';
@import 'helpers/fonts';
@import 'helpers/text';
@import 'helpers/alignment';
@import 'helpers/cursor';
@import 'helpers/image';
@import 'helpers/icons';

// NOTE: Generate all helpers css-classes (See: STYLES.md for a complete list)
@each $key, $utility in $utilities {
  $values: map-get($utility, values);

  @each $class-modifier, $value in $values {
    $properties: map-get($utility, property);
    $property-class-prefix: map-get($utility, class);
    $property-class-prefix-hyphen: if(
      $property-class-prefix== '',
      $property-class-prefix,
      $property-class-prefix + '-'
    );

    @if type-of($properties) == 'string' {
      $properties: append((), $properties);
    }

    .#{$property-class-prefix-hyphen + $class-modifier} {
      @each $property in $properties {
        #{$property}: $value !important;
      }
    }
  }
}
