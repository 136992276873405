@import '~@angular/material/theming';
@import 'app-theme';
@import './themes/roboto';

@import '/src/app/theme/var';

@import '~@angular/material/theming';
.mat-checkbox-layout {
  white-space: normal !important;
}

@include mat-core();
@include angular-material-theme($dynamic-app-theme);
@include angular-material-typography($dynamic-typography);

@include app-theme($dynamic-app-theme);
$primary: mat-palette($dynamic-theme-primary);
$accent: mat-palette($dynamic-theme-accent);

$theme: mat-light-theme($primary, $accent);
$config: mat-typography-config();

.accent-color {
  color: mat-color($accent) !important;
}

.accent-bg-color {
  background-color: mat-color($accent) !important;
}
