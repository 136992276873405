@mixin matero-topmenu-theme($theme) {
  $background: mat-color($theme, background);
  $foreground: mat-color($theme, foreground);

  .matero-topmenu {
    background: mat-color($background, app-bar);
    color: mat-color($foreground, text);

    .mat-button {
      &.active {
        background-color: mat-color($background, focused-button);
      }
    }
  }
}
