@mixin badge() {
  display: inline-block;
  min-width: 18px;
  padding: 3px 5px;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  color: #fff;
  background-color: #757575;
  border-radius: 2px;
}
