/* You can add global styles to this file, and also import other style files */
@import 'app/theme/style';
@import 'styles/themes';
@import 'styles/custom';
@import 'styles/grid';
@import 'styles/helpers';
@import 'styles/plugins';

#no-overscroll{
    overscroll-behavior: none !important;
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
}