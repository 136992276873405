// NOTE: Example for custom theming
@mixin custom-table-theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');

  $row-hover-bg: mat-color($mat-indigo, 50);
  $row-odd-bg: mat-color($mat-gray, 100);

  $row-hover-bg-dark: mat-color($mat-blue-gray, 900);
  // $row-odd-bg-dark: lighten(mat-color($mat-gray, 900), 10%);
  $row-odd-bg-dark: mat-color($mat-gray, 800);  

  mtx-grid.mtx-grid {
    .mat-table {
      &.mat-table-striped {
        .mat-row-odd {
          background-color: if($is-dark-theme, $row-odd-bg-dark, $row-odd-bg);
        }
      }

      &.mat-table-hover {
        .mat-row {
          &:hover {
            background-color: if($is-dark-theme, $row-hover-bg-dark, $row-hover-bg);
          }
        }
      }

      .mat-row {
        &.selected {
          background-color: if($is-dark-theme, $row-hover-bg-dark, $row-hover-bg);
        }
      }
    }
  }
}
