@mixin matero-reboot-theme($theme) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);

  a {
    color: mat-color($primary);

    &:hover {
      //color: lighten(mat-color($primary), 10%);
      color: mat-color(mat-palette($primary, 400));
    }
  }

  code {
    background-color: rgba(mat-color($foreground, secondary-text), .03);
  }
}
